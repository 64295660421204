import React, { useState, useEffect } from 'react'
import { PageHeader, Seo } from '../../components'
import DataGrid from '../../components/DataGrid'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { QServiceReports } from '../../services/q-rervices-reporting'
import { getColumns } from '../../components/DataGrid/reportingConfig/rank-advancement'

const RankAdvancement = () => {
  const { qUser } = useAuthContext()
  const {
    prismicData: { prismicReports },
  } = usePrismic()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState('')

  const dateEnd = new Date()
  const dateStart = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), 1) // Current month only
  const input = {
    legacyAssociateId: qUser?.legacyAssociateId,
    dateStart: dateStart,
    dateEnd: dateEnd,
    associateTypes: ['AMBASSADOR'],
    associateStatuses: ['ACTIVE', 'TERMINATED'],
    filterTypes: ['STAT_DATE'],
    countryCodes: [],
  }

  const columns = getColumns(prismicReports)

  const getRankAdvancementReport = async () => {
    setLoading(true)
    await QServiceReports.Reports.getRankAdvancementReport({ input })
      .then(response => setData(response))
      .catch(err => setError(err))
    setLoading(false)
  }

  useEffect(() => {
    if (qUser) {
      getRankAdvancementReport()
    }
  }, [qUser])

  return (
    <>
      <Seo title={prismicReports.rank_advancement} />
      <PageHeader exitRoute="/reports">
        {prismicReports.rank_advancement}
      </PageHeader>
      <DataGrid columns={columns} data={data} loading={loading} error={error} />
    </>
  )
}

export default RankAdvancement
