import { GridColDef } from '@mui/x-data-grid-pro'

export const getColumns = prismic => {
  const columns: GridColDef[] = [
    {
      field: 'rowId',
      headerName: 'rowId',
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'legacyAssociateId',
      headerName: prismic.id,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'firstName',
      headerName: prismic.first_name,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'lastName',
      headerName: prismic.last_name,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'associateStatus',
      headerName: prismic.status,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'phone',
      headerName: prismic.phone,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'email',
      headerName: prismic.email,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'countryName',
      headerName: prismic.country,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'signupDate',
      headerName: prismic.start_date,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'enrollerName',
      headerName: prismic.sponsor,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'enrollerLegacyAssociateId',
      headerName: prismic.sponsor_id,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'priorOvRankName',
      headerName: prismic.previous_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'currentOvRankName',
      headerName: prismic.current_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'highestOvRankName',
      headerName: prismic.highest_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'priorCvRankName',
      headerName: prismic.previous_cv_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'currentCvRankName',
      headerName: 'Current CV Rank',
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'highestCvRankName',
      headerName: prismic.current_cv_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'treeLevel',
      headerName: prismic.level,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
  ]
  return columns
}
